export default {
  description: 'View some screenshots taken from our game.',
  title: 'Throbax TD Screenshots',
  gallery: 'A few screenshots from an earlier version',
  gallery1: 'Splash Screen',
  description1: 'This is our splash screen since we got rid of the splash video.',
  gallery2: 'Main Menu',
  gallery3: 'Options',
  gallery4: 'Graphics Options',
  gallery5: 'Sound Options',
  gallery6: 'Game Options',
  gallery7: 'Level Selection',
  gallery8: 'Intro Takeout',
  gallery9: 'Intro Takeout',
  gallery10: 'Ughlar The Mighty',
  gallery11: 'First Level',
  gallery12: 'Already Fighting',
  gallery13: 'Different Levels',
  gallery14: 'Beware of the Darkness',
  gallery15: 'Hero Command Queue',
  gallery16: 'Some of the Action',
  gallery17: 'Different Enemies',
  gallery18: 'Different Towers',
  gallery19: 'Establishing Power Support',
  gallery20: 'Heavy Action',
  gallery21: 'Zooming',
  gallery22: 'Lightning Effects',
  gallery23: 'Poison Towers'
}
